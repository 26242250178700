import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { ProfileGetType } from '../types/api/profile/ProfileGetType';
import { editProfile, getProfile } from '../../../services/profileService';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

interface ProfileContextType {
  profileData: ProfileGetType | undefined;
  setProfileData: (state: ProfileGetType) => void;
  onEditProfile: (profileData : ProfileGetType) => void
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const ProfileContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate  = useNavigate();
  const [profileData, setProfileData] = useState<ProfileGetType | undefined>(undefined);

  const onEditProfile = async(profileData : ProfileGetType) => {
    const res = await editProfile(profileData)
    setProfileData(res.data);
  }

  const fetchData = async() => {
    await getProfile()
      .then(res => {
        setProfileData(res.data); 
      })
      .catch((e : AxiosError) => {
        if(e.status === 401){
          localStorage.removeItem('token')
          navigate('/auth/login');
        }
      })
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ProfileContext.Provider value={{ profileData, setProfileData, onEditProfile}}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('prifleContext must be used within a NavContextProvider');
  }
  return context;
};