import { FC, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import CustomDatePicker from '../../../../components/shared/filters/CustomDatePicker';
import DistanceSlider from '../../../../components/shared/filters/DistanceSlider';
import { getTravelTypes } from '../../../../../../services/travelsService';
import CustomSelect from '../../../../components/shared/select/CustomSelect';
import { getGroupsToSeletc } from '../../../../../../services/groupService';
import { useNavContext } from '../../../../context/NavContext';
import { TravelFiltersType } from '../../types/travelFiltersType';

interface TravelFiltersProps { 
    onFilterApplay : (appliedFilters : TravelFiltersType) => void;
    onClearFilters : () => void;
}

interface SelectFieldsHandler {
    travelTypes: { id: number, name: string }[],
    travelGroups: { id: number, name: string }[],
}

const TravelFilters: FC<TravelFiltersProps> = (props) => {
    const { windowSize } = useNavContext();
    const [selectOptions, setSelectOptions] = useState<SelectFieldsHandler>({
        travelTypes: [],
        travelGroups: [],
    })
    const [activeFilters, setActiveFilters] = useState<TravelFiltersType>({
        minTravelDate: undefined,
        maxTravelDate: undefined,
        minDistance: 0,
        maxDistance: 500,
        travelGroup: null,
        travelType: null,
    })

    const fetchTravelTypes = async () => {
        await getTravelTypes()
            .then(res => {
                setSelectOptions((prev) => ({
                    ...prev,
                    travelTypes: res.data
                }));
            })
    }

    const fetchTravelGroups = async () => {
        await getGroupsToSeletc()
            .then(res => {
                setSelectOptions((prev) => ({
                    ...prev,
                    travelGroups: res.data
                }));
            })
    }

    useEffect(() => {
        fetchTravelTypes();
        fetchTravelGroups();
    }, [])

    const isMdBreakpoint = () => {
        if (windowSize < 992) return false;
        return true;
    }

    return (
        <Grid item container xs={12}>
            <Grid item xs={12} md={4} lg={3} sx={{ padding: isMdBreakpoint() ? '0 24px 0 0' : '0' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ padding: '0 0 8px 0', zIndex: 0 }}>
                        <CustomDatePicker
                            onChange={(value) => {
                                setActiveFilters((prev) => ({
                                    ...prev,
                                    minTravelDate : value 
                                }))
                            }}
                            label='Start date'
                            value={activeFilters.minTravelDate || ""}
                        />
                    </Box>
                    <Box sx={{ padding: '8px 0 0 0', zIndex: 0 }}>
                        <CustomDatePicker
                            label='End date'
                            value={activeFilters.maxTravelDate || ""}
                            onChange={(value) => {
                                setActiveFilters((prev) => ({
                                    ...prev,
                                    maxTravelDate : value 
                                }))
                            }}
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={6} sx={{ padding: isMdBreakpoint() ? '0 24px 0 24px' : '20px 0' }} >
                <Box sx={{ padding: '0 36px 8px 36px', zIndex: 0, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                    <DistanceSlider
                        minValue={activeFilters.minDistance}
                        maxValue={activeFilters.maxDistance}
                        onChangeValue={(values) => {
                            setActiveFilters((prev) => ({
                                ...prev,
                                minDistance: values[0],
                                maxDistance: values[1]
                            }))
                        }}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ padding: isMdBreakpoint() ? '0 0 0 24px' : '0' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ padding: '0 0 8px 0', zIndex: 0 }}>
                        <CustomSelect
                            options={selectOptions.travelTypes}
                            value={activeFilters.travelType || undefined}
                            onChange={(id) =>
                                setActiveFilters((prev) => ({
                                    ...prev,
                                    travelType: id,
                                }))
                            }
                            label='Travel type'
                        />
                    </Box>
                    <Box sx={{ padding: '8px 0 0 0', zIndex: 0 }}>
                        <CustomSelect
                            options={selectOptions.travelGroups}
                            value={activeFilters.travelGroup || undefined}
                            onChange={(id) =>
                                setActiveFilters((prev) => ({
                                    ...prev,
                                    travelGroup: id,
                                }))
                            }
                            label='Travel group'
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ padding: '16px 0 0 0', display: 'flex', justifyContent: 'flex-end' }}>
                <Button 
                    variant="contained" 
                    sx={{ margin: '0 16px 0 0' }} 
                    onClick={() => props.onFilterApplay(activeFilters)}>
                        Filter
                    </Button>
                <Button 
                    variant="contained" 
                    color='error' 
                    onClick={() => {
                        setActiveFilters(() => ({
                            minTravelDate: undefined,
                            maxTravelDate: undefined,
                            minDistance: 0,
                            maxDistance: 500,
                            travelGroup: null,
                            travelType: null,
                        }))
                        props.onClearFilters();
                    }}>
                        Clear
                    </Button>
            </Grid>
        </Grid>
    );
}

export default TravelFilters;
