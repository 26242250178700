import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { format } from 'date-fns';
import { useState, type FC } from 'react';
import FrameIcon from '../../../../../shared/ui/frameIcon/FrameIcon';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import HikingIcon from '@mui/icons-material/Hiking';
import { useAppContext } from '../../../../../core/context/AppContext';
import { useNavContext } from '../../../context/NavContext';
import { useNavigate } from 'react-router-dom';
import { deleteTravel } from '../../../../../services/travelsService';
import DescriptionIcon from '@mui/icons-material/Description';
import { dateFormat } from '../../../../../core/helpers/dateFormat';
import Action from '../../../components/actionDropdown/Action';
import { ActionItemType } from '../../../types/ui/actionItemType';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface TravelGroupFormProps {
    travelId: number,
    travelDetails: FormValues,
    travelTypes: { id: number, name: string }[],
    onEdit: (formValues: FormValues) => void,
}

export interface FormValues {
    travelName: string,
    travelType: number,
    date: Date,
    description: string
}

const TravelGroupForm: FC<TravelGroupFormProps> = ({ travelDetails, travelTypes, onEdit, travelId }) => {
    const navigate = useNavigate();
    const { isLoading } = useAppContext();
    const { windowSize } = useNavContext();
    const [formValues, setFormValues] = useState<FormValues>(travelDetails);

    const isMdBreakpoint = (): boolean => {
        if (windowSize >= 900) return true;
        return false;
    }

    const onTravelTypeSelect = (event: SelectChangeEvent<number>) => {
        setFormValues((prev) => ({
            ...prev,
            travelType: Number(event.target.value),
        }));
        onEdit({
            ...formValues,
            travelType: Number(event.target.value),
        });
    };

    const onTravelDelete = async () => {
        await deleteTravel(travelId)
            .then(res => {
                navigate('/app/travels');
            })
    }

    const travelDetailsActions: ActionItemType[] = [
        {
            label: 'Delete travel',
            icon: DeleteForeverIcon,
            onAction: () => onTravelDelete()
        }
    ]


    return (
        <Grid container item xs={12}>
            <Grid item xs={12} md={6} sx={{ padding: isMdBreakpoint() ? '0 8px 0 0' : '0 0 16px 0' }}>
                <Box sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '6px',
                    boxShadow: 3,
                    height: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                }}>
                    <Action
                        actionName='travelDetailsAction'
                        actionItems={travelDetailsActions}
                    />
                    <Box sx={{
                        width: '85%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 0 20px 0',
                    }}>
                        <FrameIcon
                            icon={StickyNote2Icon}
                        />
                        <TextField
                            disabled={isLoading}
                            id="standard-basic"
                            label="Travel name"
                            variant="standard"
                            value={formValues.travelName}
                            onBlur={() => onEdit(formValues)}
                            onChange={(event) => {
                                setFormValues(prev => ({
                                    ...prev,
                                    travelName: event.target.value
                                }))
                            }}
                            sx={{
                                width: '90%',
                                marginLeft: '40px'
                            }} />
                    </Box>
                    <Box sx={{
                        width: '85%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '20px 0 20px 0',
                    }}>
                        <FrameIcon
                            icon={HikingIcon}
                        />
                        <FormControl variant="standard" sx={{ width: '90%', marginLeft: '40px' }}>
                            <InputLabel id="demo-simple-select-standard-label">Travel type</InputLabel>
                            <Select
                                disabled={isLoading}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={formValues.travelType}
                                onChange={onTravelTypeSelect}
                                label="Age"
                                variant='standard'>
                                {travelTypes.map((item, index)=> (
                                    <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{
                        width: '85%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '20px 0 0 0',
                    }}>
                        <FrameIcon
                            icon={EventAvailableIcon}
                        />
                        <TextField
                            id="standard-basic"
                            label="Travel date"
                            variant="standard"
                            value={format(new Date(formValues.date), dateFormat)}
                            onChange={(event) => {
                                setFormValues((prev) => ({
                                    ...prev,
                                    description: event.target.value
                                }));
                            }}
                            disabled={true}
                            sx={{
                                width: '90%',
                                marginLeft: '40px',
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: "#000000",
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                },
                                '& .MuiInputLabel-root.Mui-disabled': {
                                    color: '#686868',
                                },
                            }} />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ padding: isMdBreakpoint() ? '0 0 0 8px' : '16px 0 0 0' }}>
                <Box sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '6px',
                    boxShadow: 3,
                    height: '300px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'start',
                    position: 'relative'
                }}>
                    <Box sx={{ margin: '40px 0 0 40px' }}>
                        <FrameIcon
                            icon={DescriptionIcon}
                        />
                    </Box>
                    <TextField
                        variant='standard'
                        onBlur={() => onEdit(formValues)}
                        onChange={(event) => {
                            setFormValues(prev => ({
                                ...prev,
                                description: event.target.value
                            }))
                        }}
                        id="outlined-multiline-flexible"
                        label="Description"
                        multiline
                        rows={10}
                        fullWidth
                        value={formValues.description}
                        sx={{
                            margin: '40px 0 0 0',
                            width: '70%',
                            marginLeft: '40px',
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: "#000000",
                            },
                            '& .MuiInput-underline:before': {
                                borderBottom: 'none',
                            },
                            '& .MuiInputLabel-root.Mui-disabled': {
                                color: '#686868',
                            },
                        }} />
                </Box>
            </Grid>
        </Grid >
    );
}

export default TravelGroupForm;
