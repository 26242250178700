import { Avatar, Box, Typography, useTheme } from '@mui/material';
import type { FC } from 'react';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useNavigate } from 'react-router-dom';
import { useDropdownContext } from '../../../../../core/context/DropdownContext';
import { useProfileContext } from '../../../context/ProfileContext';

interface LoggedUserProps {}

const LoggedUser: FC<LoggedUserProps> = () => {
    const theme = useTheme();
    const { profileData } = useProfileContext();
    const { setOpenDropdown } = useDropdownContext();
    const navigate = useNavigate();

    const logOut = () => {
        localStorage.removeItem('token');
        setOpenDropdown(null);
        navigate('/auth/login');
    }

    return (
        <Box sx={{width: '140px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px'}}>
            <Avatar
                src={profileData?.profilePic}
                sx={{width: '60px', height: '60px'}}>
            </Avatar>
            <Typography variant='body1' sx={{margin: '10px 0 0 0'}}>{profileData?.user.username || ''}</Typography>
            <Box sx={{
                position: 'absolute',
                height: '1px',
                width: '100%',
                backgroundColor: '#e7e7e7',
                bottom: '52px'
                }}></Box> 
            <Box
                onClick={logOut} 
                sx={{
                    margin: '20px 0 0 0', 
                    fontWeight: '300', 
                    width: '100%',
                    display: 'flex', 
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    color: theme.palette.text.secondary,
                    transition: 'all ease-in-out 0.2s',
                    '&:hover': {
                        color: theme.palette.text.primary
                    },
                    }}>
                    <PowerSettingsNewIcon/>
                    <Box sx={{
                        marginLeft: '10px',
                    }}>
                        Log out
                    </Box>
                </Box>
        </Box>
    );
}

export default LoggedUser;
