import { Box, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import type { FC } from 'react';
import { TravelsGetType } from '../../../types/api/travels/travelsGetType';
import NoDataComponent from './components/NoDataComponent';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../../core/context/AppContext';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { TravelSortingType } from '../../../types/ui/travelSortingType';
import NorthIcon from '@mui/icons-material/North';
import { South } from '@mui/icons-material';
import { setSorting } from '../../../helpers/travelSortingHelper';
import { dateFormat } from '../../../../../core/helpers/dateFormat';
import TableLoader from '../../tableLoader/TableLoader';

interface TravelsTableProps {
    travels: TravelsGetType[];
    pagination?: {
        totalPages: number,
        currentPage: number,
        onChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
    }
    sorting: {
        currentOptions: TravelSortingType | undefined
        onSortingApplay: (newSorting: TravelSortingType) => void;
    } | null
}

const TravelsTable: FC<TravelsTableProps> = ({ travels, pagination, sorting }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { isLoading } = useAppContext();

    const onRowClicked = (travelId: number): void => {
        navigate(`/app/travels/${travelId}`)
    }

    const setIcon = (sortField: string) => {
        if (sorting?.currentOptions?.sortOrder === undefined || sorting.currentOptions.sortField !== sortField) return <SwapVertIcon />;
        if (sorting.currentOptions.sortOrder === 'asc') return <NorthIcon />
        return <South />
    }

    const onSortingClicked = (newSortingField: string): void => {
        if (sorting && sorting.currentOptions) {
            const { sortField, sortOrder } = sorting.currentOptions;
            const newSortingOptions = setSorting(sortField, newSortingField, sortOrder);
            sorting.onSortingApplay(newSortingOptions);
        }
    }

    return (
        <>
            <TableContainer component={Paper} sx={{ boxShadow: 5, borderRadius: '6px', minHeight: '300px' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: theme.palette.primary.light }}>
                            <TableCell sx={{ color: 'white', fontWeight: '600', width: '30%', border: 'none' }}>Title</TableCell>
                            <TableCell
                                onClick={sorting ? () => onSortingClicked('distance') : undefined}
                                sx={{
                                    color: 'white',
                                    fontWeight: '600',
                                    width: '10%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: 'none'
                                }}>
                                Distance&nbsp;(km)
                                {sorting && <Box sx={{ cursor: 'pointer' }}>{setIcon('distance')}</Box>}
                            </TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: '600', width: '15%', border: 'none' }}>Travel Type</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: '600', width: '20%', border: 'none' }}>Travel Group</TableCell>
                            <TableCell
                                onClick={sorting ? () => onSortingClicked('travelDate') : undefined}
                                sx={{
                                    color: 'white',
                                    fontWeight: '600',
                                    width: '25%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: 'none'
                                }}>
                                Date
                                {sorting && <Box sx={{ cursor: 'pointer' }}>{setIcon('travelDate')}</Box>}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ position: 'relative' }}>
                        {isLoading ? (
                            <TableLoader />
                        ) : (
                            travels.map((travel, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { cursor: 'pointer' } }}
                                    onClick={() => onRowClicked(travel.id)}
                                >
                                    <TableCell sx={{ padding: '24px 20px', fontWeight: '400' }} component="th" scope="row">
                                        {travel.name}
                                    </TableCell>
                                    <TableCell sx={{ padding: '24px 20px', fontWeight: '400' }}>{(travel.distance / 1000).toFixed(2)}</TableCell>
                                    <TableCell sx={{ padding: '24px 20px', fontWeight: '400' }}>{travel.travelType?.name}</TableCell>
                                    <TableCell sx={{ padding: '24px 20px', fontWeight: '400' }}>{travel.travelGroup ? travel.travelGroup.name : 'none'}</TableCell>
                                    <TableCell sx={{ padding: '24px 20px', fontWeight: '400' }}>{format(new Date(travel.travelDate), dateFormat)}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
                {
                    travels.length < 1 ?
                        <NoDataComponent
                            size={1}
                            title='No data'
                            details="You don not have any trips yet"
                        /> : null
                }
            </TableContainer>
            {pagination && pagination.totalPages > 1 &&
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '20px 0 0 0'
                }}>
                    <Stack spacing={2}>
                        <Pagination
                            count={pagination.totalPages}
                            color='primary'
                            size='large'
                            page={pagination.currentPage}
                            onChange={(event, page) => pagination.onChangePage(event, page)}
                        />
                    </Stack>
                </Box>
            }
        </>
    );
}

export default TravelsTable;
