import type { FC } from 'react';
import { GroupGetType } from '../../../../types/api/groups/GroupGetType';
import { TableRow, TableCell, useTheme, Typography, SxProps } from '@mui/material';
import { dateFormat } from '../../../../../../core/helpers/dateFormat';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

interface GroupTableRowProps {
    group: GroupGetType
}

const GroupTableRow: FC<GroupTableRowProps> = ({ group }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleRowClicked = (id: number) => {
        navigate(`/app/groups/${id}`);
    }

    const typographyStyles : SxProps = { fontWeight: 300, color: theme.palette.text.secondary, fontSize: '12px' }

    const trimDesc = () : string => {
        if(group.description && group.description.length > 100) return group.description.slice(0, 100) + '...'
        return group.description;
    }

    return (
        <TableRow
            sx={{ cursor: 'pointer' }}
            key={group.travelCount}
            hover
            onClick={() => handleRowClicked(group.id)}
        >
            <TableCell>
                <Typography variant='body2' sx={typographyStyles}>Group name</Typography>
                <Typography variant='body2'>{group.name}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2' sx={typographyStyles}>Travels count</Typography>
                <Typography variant='body2'>{group.travelCount}</Typography>
            </TableCell>
            <TableCell sx={{maxWidth: '300px'}}>
                <Typography variant='body2' sx={typographyStyles}>Description</Typography>
                <Typography variant='body2'>{trimDesc()}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2' sx={typographyStyles}>Last activity</Typography>
                <Typography variant='body2'>{group.lastTravelDate ? format(new Date(group.lastTravelDate), dateFormat) : 'none'}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2' sx={typographyStyles}>Created date</Typography>
                <Typography variant='body2'>{group.lastTravelDate ? format(new Date(group.lastTravelDate), dateFormat) : 'none'}</Typography>
            </TableCell>
        </TableRow>
    );
}

export default GroupTableRow;
