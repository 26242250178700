import { Typography } from '@mui/material';
import type { FC } from 'react';

interface AboutPageProps {}

const AboutPage: FC<AboutPageProps> = () => {
    return (
        <div>
            <Typography variant='h2' sx={{marginBottom: '20px'}}>FollowMe</Typography>
            <Typography variant='h3' sx={{marginBottom: '20px'}}>Collect your trips everywhere you are</Typography>
            <Typography variant='subtitle1' sx={{marginBottom: '20px'}}>The FollowMe application is used to record trips, biking, walking and all activities as well as collecting them in a convenient and attractive way. All your trips can be grouped, combined, described and shared on community portals.We wish you to visit the whole world with the FollowMe application! Have a nice trip!</Typography>
            <Typography variant='subtitle1' sx={{marginBottom: '20px'}}>This application was created in WEBimpuls IT laboratory. WEBimpuls Company has been operating on the market since 2010. We combine two kinds of our skills: programming and marketing to develop companies on the Internet. We design and manufacture websites, stores, portals and advanced WEB applications. Our marketing department takes care of the image of our clients on the Internet. The high quality of the services provided is the result of many years of experience of people creating a harmonious and professional team. At any time, you can use WEBimpuls knowledge and experience with our help - we are happy to share our knowledge.</Typography>
            <Typography variant='subtitle1'>App designer and project manager Konrad Ogar</Typography>
            <Typography variant='subtitle1'>konrad.ogar@webimpuls.pl</Typography>
            <Typography variant='subtitle1' sx={{marginTop: '20px'}}>Developers:</Typography>
            <Typography variant='subtitle1'>Frontend: Dariusz Wantuch, Maksymilian Baryczka, Przemysław Cichoń, Bartosz Kubik</Typography>
            <Typography variant='subtitle1'>Mobile: Arkadiusz Bodura, Sebastian Cetera, Jakub Zegar, Michał Polak</Typography>
            <Typography variant='subtitle1'>Backend: Bartosz Wojdałowicz, Paweł Hajdo</Typography>
        </div>
    );
}

export default AboutPage;
