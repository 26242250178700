import { Box, ThemeProvider, CircularProgress } from '@mui/material';
import './App.css';
import theme from './core/themes/theme';
import { AppProvider, useAppContext } from './core/context/AppContext';
import { useEffect, useState } from 'react';
import { setupInterceptors } from './api/config';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { NavContextProvider } from './pages/user/context/NavContext';
import { DropdownProvider } from './core/context/DropdownContext';
import { AccountConfirmationProvider } from './core/context/AccountConfirmationContext';

function App() {
  const { setIsLoading, setError, setSuccess } = useAppContext();
  const [isReady, setIsReady] = useState(false); // Nowy stan do zarządzania gotowością aplikacji

  useEffect(() => {
    setupInterceptors(setIsLoading, setError, setSuccess);
    setIsReady(true); // Ustawienie stanu na gotowe po konfiguracji interceptorów
  }, [setIsLoading, setError, setSuccess]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          width: '100%',
          bgcolor: `${theme.palette.background.default}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isReady ? <AppRoutes /> : <CircularProgress />} {/* Warunkowe renderowanie */}
      </Box>
    </ThemeProvider>
  );
}

const AppWrapper = () => (
  <AppProvider>
    <AccountConfirmationProvider>
      <NavContextProvider>
        <DropdownProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </DropdownProvider>
      </NavContextProvider>
    </AccountConfirmationProvider>
  </AppProvider>
);

export default AppWrapper;
