import { Grid } from '@mui/material';
import type { FC } from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useNavContext } from '../../../context/NavContext';
import TravelStatsItem from '../../../components/shared/travelStatsItem/TravelStatsItem';
import { TravelsGetType } from '../../../types/api/travels/travelsGetType';

interface LastTravelStatsProps{
    tableHeight: number;
    lastTravels : TravelsGetType[]
}

const LastTravelStats: FC<LastTravelStatsProps> = (props) => {
    const { windowSize } = useNavContext();
    
    const calculateStats = () => {
        let totalDistance : number = 0;
        let maxSpeed : number = 0;
        let totalDuration : number = 0;
        let avgSpeedSum : number = 0;
    
        props.lastTravels.forEach((travel) => {
          totalDistance += travel.distance || 0;
          maxSpeed = Math.max(maxSpeed, travel.maxSpeed || 0);
          totalDuration += travel.duration || 0; // Zakładam, że duration jest w sekundach
          avgSpeedSum += travel.avgSpeed || 0;
        });
    
        let avgSpeed = props.lastTravels.length > 0 ? avgSpeedSum / props.lastTravels.length : 0;
    
        totalDistance = Number((totalDistance / 1000).toFixed(2))
        avgSpeed = Number((avgSpeed).toFixed(2))

        return {
          totalDistance,
          maxSpeed,
          avgSpeed,
          totalDuration,
        };
      };
    
      const stats = calculateStats();

    const isMdBreakpoint = () : boolean => {
        if(windowSize > 900 && windowSize < 1536) return true;
        return false;
    }

    const setHeight = () : number => {
        if(isMdBreakpoint()) return 180;
        if(windowSize <= 900) return 360; 
        return props.tableHeight;
    }

    const formatDuration = (duration: number): string => {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <Grid container sx={{height: `${setHeight()}px`}}>
            <Grid 
                item
                xs={6} 
                md={3} 
                xl={6} 
                sx={{ padding: isMdBreakpoint() ? '0 8px 0 8px' : '0 8px 8px 0'}}>
                <TravelStatsItem 
                    label='Distance'
                    value={stats.totalDistance.toFixed(2)}
                    unit='km'
                    icon={ModeOfTravelIcon}
                />
            </Grid>
            <Grid item xs={6} md={3} xl={6} sx={{padding: isMdBreakpoint() ? '0 8px 0 8px' : '0 0 8px 8px'}}>
                <TravelStatsItem
                    label='Max speed'
                    value={stats.maxSpeed}
                    unit='km/h'
                    icon={SpeedIcon}
                />
            </Grid>
            <Grid item xs={6} md={3} xl={6} sx={{padding: isMdBreakpoint() ? '0 8px 0 8px' : '8px 8px 0 0'}}>
                <TravelStatsItem
                    label='Avg speed'
                    value={stats.avgSpeed}
                    unit='km/h'
                    icon={SpeedIcon}
                />
            </Grid>
            <Grid item xs={6} md={3} xl={6} sx={{padding: isMdBreakpoint() ? '0 0 0 8px' : '8px 0 0 8px'}}>
                <TravelStatsItem
                    label='Duration'
                    value={formatDuration(stats.totalDuration)}
                    unit=''
                    icon={HourglassTopIcon}
                />
            </Grid>
        </Grid>
    );
}

export default LastTravelStats;
