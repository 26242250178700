import { TableRow, TableCell } from '@mui/material';
import type { FC } from 'react';
import Loader from '../../../../shared/ui/loader/Loader';

interface TableLoaderProps { }

const TableLoader: FC<TableLoaderProps> = () => {
    return (
        <TableRow>
            <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                <Loader />
            </TableCell>
        </TableRow>
    );
}

export default TableLoader;
