import { useEffect, useState, type FC } from 'react';
import ContentWrapper from '../../components/contentWrapper/contentWrapper';
import { Box, Grid } from '@mui/material';
import GroupTable from './components/groupTable/GroupTable';
import { GroupGetType } from '../../types/api/groups/GroupGetType';
import { getGroups } from '../../../../services/groupService';
import { useDropdownContext } from '../../../../core/context/DropdownContext';
import ActionDropdown from '../../components/actionDropdown/ActionDropdown';
import AddGroup from './addGroup/AddGroup';

interface GroupPageProps { }

const GroupPage: FC<GroupPageProps> = () => {
    const { openDropdown, setOpenDropdown } = useDropdownContext();
    const [groups, setGroups] = useState<GroupGetType[]>([]);

    const fetchData = async () => {
        await getGroups()
            .then(res => {
                setGroups(res.data)
            })
            .catch((e: Error) => {
              
            })
    }

    useEffect(() => {
        document.title = "FollowMe | Groups"; 
        fetchData();
    }, [])

    const onGroupAdd = (addedGroup: GroupGetType) => {
        setOpenDropdown(null);
        setGroups([...groups, addedGroup])
    }

    return (
        <ContentWrapper
            pageTitle='Your groups'
        >
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', position: 'relative' }}>
                    {openDropdown === "addGroup" &&
                            <ActionDropdown sx={{ zIndex: 1}}>
                                <AddGroup
                                    onSuccess={(group) => onGroupAdd(group)}
                                />
                            </ActionDropdown>
                        }
                </Box>
                <GroupTable
                    groups={groups}
                />
            </Grid>
        </ContentWrapper>
    );
}

export default GroupPage;
