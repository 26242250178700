import { TableContainer, Paper, Table, TableBody, Button } from '@mui/material';
import type { FC } from 'react';
import { GroupGetType } from '../../../../types/api/groups/GroupGetType';
import NoDataComponent from '../../../../components/shared/travelTable/components/NoDataComponent';
import TableTitle from '../../../../components/tables/tableTitle/TableTitle';
import { useDropdownContext } from '../../../../../../core/context/DropdownContext';
import GroupTableRow from './GroupTableRow';

interface GroupTableProps {
    groups: GroupGetType[]
}

const GroupTable: FC<GroupTableProps> = ({ groups }) => {
    const { setOpenDropdown } = useDropdownContext();

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 5, borderRadius: '6px' }}>
          <TableTitle title="All groups">
                <Button variant='outlined' size='small' onClick={() => setOpenDropdown('addGroup')}>+ Add group</Button>
            </TableTitle>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
              {groups.map((group, index) => (
                <GroupTableRow group={group} key={index}/>
              ))}
            </TableBody>
            </Table>
            {groups.length === 0 && <NoDataComponent size={1} title='No groups' details='You do not have any groups yet'/>}
        </TableContainer>
    );
}

export default GroupTable;
