import { Box, Card, Typography, Alert } from '@mui/material';
import type { FC } from 'react';
import theme from '../../../../core/themes/theme';
import DynamicForm from '../../../../shared/ui/form/DynamicForm';
import background from '../../../../assets/images/background.jpg';
import { RegisterType } from '../../types/registerType';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../../core/context/AppContext';
import { registerFormConfig } from '../../forms/registerFormConfig';
import { LoadingButton } from '@mui/lab';
import { passwordReset, register } from '../../../../services/authService';
import { useNavigate } from 'react-router-dom';

interface RegisterPageProps {}

const RegisterPage: FC<RegisterPageProps> = () => {
    const navigate = useNavigate();
    
    const {isLoading, error, setError, success} = useAppContext();
    const[formValues, setValues]=React.useState<RegisterType>({
        email : '',
        password : '',
        confirmPassword: '',
    })
    const onFormValuesChange = (data : Partial<RegisterType>) => {
        setValues((prevValue) => ({
            ...prevValue,
            ...data,
        }));
        setError(null);
    };

    const registerHandle = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if(formValues.confirmPassword !== formValues.password){
            setError({status: 400, message: 'Passwords do not match'})
            return
        }
        if (formValues.password.length < 8) {
            setError({ status: 400, message: 'Password must be 8 characters long' });
            return;
        }
        if (!formValues.email || !emailRegex.test(formValues.email)) {
            setError({ status: 400, message: 'Invalid email address' });
            return;
        }
        await register(formValues)
            .then(res => navigate('/auth/login'))
            .catch((e : Error) => {})
    };

    useEffect(() => {
        document.title = "FollowMe | Sign up"; 
    }, [])

    return (
        <Box>
            <Card
                sx={{ width: 360, borderRadius: '10px', boxShadow: '0px 0px 24px -7px rgba(66, 68, 90, 1);' }}
            >
                <Box
                    sx={{
                        height: 200,
                        width: '100%',
                        backgroundImage: `url(${background})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            backgroundColor: '#00000049',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '0 28px'
                        }}
                    >
                        <Typography
                            variant="h3"
                            color="white">
                            Sign Up
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ padding: '40px 28px' }}>
                    <DynamicForm
                        formConfig={registerFormConfig}
                        onChange={onFormValuesChange}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            padding: '10px 0'
                        }}>
                    </Box>
                    <LoadingButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    size='large'
                    fullWidth
                    loading={isLoading}
                    onClick={registerHandle}>
                        Register
                    </LoadingButton>
                    <Typography
                        variant='body2'
                        color={theme.palette.text.secondary}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px',
                            fontWeight: '400',
                            fontSize: '12px'
                        }}>
                        Do you have an account?
                        <Typography variant='body2'
                            onClick={() => navigate('/auth/login')} 
                            color={theme.palette.primary.main} 
                            sx={{ marginLeft: '4px', cursor: 'pointer', fontWeight: '400',
                                fontSize: '12px' }}>
                            Sign in now
                        </Typography>
                    </Typography>
                </Box>
            </Card>
            {error ? 
                <Alert 
                severity="error"
                sx={{position: 'fixed', right: '20px', bottom: '20px', padding: '10px 20px'}}>
                    {error.message}
                </Alert> : null
            }
            {success ? 
                <Alert 
                severity="success"
                sx={{position: 'fixed', right: '20px', bottom: '20px', padding: '10px 20px', }}>
                    Success. Check email and confirm your account!
                </Alert> : null
            }
        </Box>
    );
}

export default RegisterPage;
